@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap";
/*! TACHYONS v4.12.0 | http://tachyons.io */
.react-flow {
  direction: ltr;
}

.react-flow__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__pane {
  z-index: 1;
  cursor: -webkit-grab;
  cursor: grab;
}

.react-flow__pane.selection {
  cursor: pointer;
}

.react-flow__pane.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__viewport {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.react-flow__renderer {
  z-index: 4;
}

.react-flow__selection {
  z-index: 6;
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible {
  outline: none;
}

.react-flow .react-flow__edges {
  pointer-events: none;
  overflow: visible;
}

.react-flow__edge-path, .react-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 1px;
  fill: none;
}

.react-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__edge.animated path.react-flow__edge-interaction {
  stroke-dasharray: none;
  animation: none;
}

.react-flow__edge.inactive {
  pointer-events: none;
}

.react-flow__edge.selected, .react-flow__edge:focus, .react-flow__edge:focus-visible {
  outline: none;
}

.react-flow__edge.selected .react-flow__edge-path, .react-flow__edge:focus .react-flow__edge-path, .react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: #555;
}

.react-flow__edge-textwrapper {
  pointer-events: all;
}

.react-flow__edge-textbg {
  fill: #fff;
}

.react-flow__edge .react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-flow__connection {
  pointer-events: none;
}

.react-flow__connection .animated {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__connectionline {
  z-index: 1001;
}

.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.react-flow__node {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__node.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__nodesselection {
  z-index: 3;
  transform-origin: 0 0;
  pointer-events: none;
}

.react-flow__nodesselection-rect {
  pointer-events: all;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__handle {
  pointer-events: none;
  background: #1a192b;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 6px;
  min-width: 5px;
  height: 6px;
  min-height: 5px;
  position: absolute;
}

.react-flow__handle.connectionindicator {
  pointer-events: all;
  cursor: crosshair;
}

.react-flow__handle-bottom {
  top: auto;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-top {
  top: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-left {
  top: 50%;
  left: -4px;
  transform: translate(0, -50%);
}

.react-flow__handle-right {
  top: 50%;
  right: -4px;
  transform: translate(0, -50%);
}

.react-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

.react-flow__panel {
  z-index: 5;
  margin: 15px;
  position: absolute;
}

.react-flow__panel.top {
  top: 0;
}

.react-flow__panel.bottom {
  bottom: 0;
}

.react-flow__panel.left {
  left: 0;
}

.react-flow__panel.right {
  right: 0;
}

.react-flow__panel.center {
  left: 50%;
  transform: translateX(-50%);
}

.react-flow__attribution {
  background: #ffffff80;
  margin: 0;
  padding: 2px 3px;
  font-size: 10px;
}

.react-flow__attribution a {
  color: #999;
  text-decoration: none;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10px;
  }
}

.react-flow__edgelabel-renderer {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.react-flow__edge.updating .react-flow__edge-path {
  stroke: #777;
}

.react-flow__edge-text {
  font-size: 10px;
}

.react-flow__node.selectable:focus, .react-flow__node.selectable:focus-visible {
  outline: none;
}

.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
  color: #222;
  text-align: center;
  background-color: #fff;
  border: 1px solid #1a192b;
  border-radius: 3px;
  width: 150px;
  padding: 10px;
  font-size: 12px;
}

.react-flow__node-default.selectable:hover, .react-flow__node-input.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
  box-shadow: 0 1px 4px 1px #00000014;
}

.react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-input.selectable:focus-visible, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 .5px #1a192b;
}

.react-flow__node-group {
  background-color: #f0f0f040;
}

.react-flow__nodesselection-rect, .react-flow__selection {
  background: #0059dc14;
  border: 1px dotted #0059dccc;
}

.react-flow__nodesselection-rect:focus, .react-flow__selection:focus, .react-flow__nodesselection-rect:focus-visible, .react-flow__selection:focus-visible {
  outline: none;
}

.react-flow__controls {
  box-shadow: 0 0 2px 1px #00000014;
}

.react-flow__controls-button {
  box-sizing: content-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fefefe;
  border: none;
  border-bottom: 1px solid #eee;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 5px;
  display: flex;
}

.react-flow__controls-button:hover {
  background: #f4f4f4;
}

.react-flow__controls-button svg {
  width: 100%;
  max-width: 12px;
  max-height: 12px;
}

.react-flow__controls-button:disabled {
  pointer-events: none;
}

.react-flow__controls-button:disabled svg {
  fill-opacity: .4;
}

.react-flow__minimap {
  background-color: #fff;
}

.react-flow__minimap svg {
  display: block;
}

.react-flow__resize-control {
  position: absolute;
}

.react-flow__resize-control.left, .react-flow__resize-control.right {
  cursor: ew-resize;
}

.react-flow__resize-control.top, .react-flow__resize-control.bottom {
  cursor: ns-resize;
}

.react-flow__resize-control.top.left, .react-flow__resize-control.bottom.right {
  cursor: nwse-resize;
}

.react-flow__resize-control.bottom.left, .react-flow__resize-control.top.right {
  cursor: nesw-resize;
}

.react-flow__resize-control.handle {
  background-color: #3367d9;
  border: 1px solid #fff;
  border-radius: 1px;
  width: 4px;
  height: 4px;
  transform: translate(-50%, -50%);
}

.react-flow__resize-control.handle.left {
  top: 50%;
  left: 0;
}

.react-flow__resize-control.handle.right {
  top: 50%;
  left: 100%;
}

.react-flow__resize-control.handle.top {
  top: 0;
  left: 50%;
}

.react-flow__resize-control.handle.bottom {
  top: 100%;
  left: 50%;
}

.react-flow__resize-control.handle.top.left, .react-flow__resize-control.handle.bottom.left {
  left: 0;
}

.react-flow__resize-control.handle.top.right, .react-flow__resize-control.handle.bottom.right {
  left: 100%;
}

.react-flow__resize-control.line {
  border: 0 solid #3367d9;
}

.react-flow__resize-control.line.left, .react-flow__resize-control.line.right {
  width: 1px;
  height: 100%;
  top: 0;
  transform: translate(-50%);
}

.react-flow__resize-control.line.left {
  border-left-width: 1px;
  left: 0;
}

.react-flow__resize-control.line.right {
  border-right-width: 1px;
  left: 100%;
}

.react-flow__resize-control.line.top, .react-flow__resize-control.line.bottom {
  width: 100%;
  height: 1px;
  left: 0;
  transform: translate(0, -50%);
}

.react-flow__resize-control.line.top {
  border-top-width: 1px;
  top: 0;
}

.react-flow__resize-control.line.bottom {
  border-bottom-width: 1px;
  top: 100%;
}

.flexlayout__layout {
  --color-text: black;
  --color-background: white;
  --color-base: white;
  --color-1: #f7f7f7;
  --color-2: #f0f0f0;
  --color-3: #e9e9e9;
  --color-4: #e2e2e2;
  --color-5: #dbdbdb;
  --color-6: #d4d4d4;
  --color-drag1: #5f86c4;
  --color-drag2: #77a677;
  --color-drag1-background: #5f86c41a;
  --color-drag2-background: #77a67713;
  --font-size: medium;
  --font-family: Roboto, Arial, sans-serif;
  --color-overflow: gray;
  --color-icon: gray;
  --color-tabset-background: var(--color-background);
  --color-tabset-background-selected: var(--color-1);
  --color-tabset-background-maximized: var(--color-6);
  --color-tabset-divider-line: var(--color-4);
  --color-tabset-header-background: var(--color-background);
  --color-tabset-header: var(--color-text);
  --color-border-tab-content: var(--color-background);
  --color-border-background: var(--color-background);
  --color-border-divider-line: var(--color-4);
  --color-tab-content: var(--color-background);
  --color-tab-selected: var(--color-text);
  --color-tab-selected-background: var(--color-4);
  --color-tab-unselected: gray;
  --color-tab-unselected-background: transparent;
  --color-tab-textbox: var(--color-text);
  --color-tab-textbox-background: var(--color-3);
  --color-border-tab-selected: var(--color-text);
  --color-border-tab-selected-background: var(--color-4);
  --color-border-tab-unselected: gray;
  --color-border-tab-unselected-background: var(--color-2);
  --color-splitter: var(--color-1);
  --color-splitter-hover: var(--color-4);
  --color-splitter-drag: var(--color-4);
  --color-drag-rect-border: #ccc;
  --color-drag-rect-background: var(--color-5);
  --color-drag-rect: var(--color-text);
  --color-popup-border: var(--color-6);
  --color-popup-unselected: var(--color-text);
  --color-popup-unselected-background: white;
  --color-popup-selected: var(--color-text);
  --color-popup-selected-background: var(--color-3);
  --color-edge-marker: #aaa;
  --color-edge-icon: #555;
  display: flex;
  position: absolute;
  inset: 0;
}

.flexlayout__layout_overlay {
  z-index: 1000;
  position: absolute;
  inset: 0;
}

.flexlayout__layout_tab_stamps {
  z-index: 100;
  flex-direction: column;
  align-items: start;
  display: flex;
  position: absolute;
  top: -10000px;
}

.flexlayout__layout_moveables {
  visibility: hidden;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20000px;
}

.flexlayout__layout_main {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  min-height: 0;
  display: flex;
  position: relative;
}

.flexlayout__layout_border_container, .flexlayout__layout_border_container_inner {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  min-height: 0;
  display: flex;
}

.flexlayout__splitter {
  background-color: var(--color-splitter);
  touch-action: none;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (hover: hover) {
  .flexlayout__splitter:hover {
    background-color: var(--color-splitter-hover);
    transition: background-color .1s ease-in 50ms;
  }
}

.flexlayout__splitter_drag {
  z-index: 1000;
  background-color: var(--color-splitter-drag);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.flexlayout__splitter_handle {
  background-color: #ccc;
  border-radius: 3px;
}

.flexlayout__splitter_handle_horz {
  width: 3px;
  height: 30px;
}

.flexlayout__splitter_handle_vert {
  width: 30px;
  height: 3px;
}

.flexlayout__splitter_extra {
  touch-action: none;
  background-color: #0000;
}

.flexlayout__outline_rect {
  pointer-events: none;
  box-sizing: border-box;
  border: 2px solid var(--color-drag1);
  background: var(--color-drag1-background);
  z-index: 1000;
  border-radius: 5px;
  position: absolute;
}

.flexlayout__outline_rect_edge {
  pointer-events: none;
  border: 2px solid var(--color-drag2);
  background: var(--color-drag2-background);
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 5px;
}

.flexlayout__edge_rect {
  z-index: 1000;
  background-color: var(--color-edge-marker);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.flexlayout__drag_rect {
  color: var(--color-drag-rect);
  background-color: var(--color-drag-rect-background);
  border: 2px solid var(--color-drag-rect-border);
  box-sizing: border-box;
  word-wrap: break-word;
  font-size: var(--font-size);
  font-family: var(--font-family);
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  padding: .3em .8em;
  display: flex;
  overflow: hidden;
}

.flexlayout__row {
  box-sizing: border-box;
  flex-basis: 0;
  min-width: 0;
  min-height: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flexlayout__tabset {
  background-color: var(--color-tabset-background);
  box-sizing: border-box;
  font-family: var(--font-family);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flexlayout__tabset_container {
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  min-height: 0;
  display: flex;
  overflow: hidden;
}

.flexlayout__tabset_tab_divider {
  width: 4px;
}

.flexlayout__tabset_content {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  min-height: 0;
  display: flex;
  position: relative;
}

.flexlayout__tabset_header {
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-tabset-divider-line);
  color: var(--color-tabset-header);
  background-color: var(--color-tabset-header-background);
  font-size: var(--font-size);
  align-items: center;
  padding: 3px 3px 3px 5px;
  display: flex;
}

.flexlayout__tabset_header_content {
  flex-grow: 1;
}

.flexlayout__tabset_tabbar_outer {
  box-sizing: border-box;
  background-color: var(--color-tabset-background);
  font-size: var(--font-size);
  display: flex;
  overflow: hidden;
}

.flexlayout__tabset_tabbar_outer_top {
  border-bottom: 1px solid var(--color-tabset-divider-line);
  padding: 0 2px;
}

.flexlayout__tabset_tabbar_outer_bottom {
  border-top: 1px solid var(--color-tabset-divider-line);
  padding: 0 2px;
}

.flexlayout__tabset_tabbar_inner {
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flexlayout__tabset_tabbar_inner_tab_container {
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  position: relative;
}

.flexlayout__tabset_tabbar_inner_tab_container_top {
  border-top: 2px solid #0000;
}

.flexlayout__tabset_tabbar_inner_tab_container_bottom {
  border-bottom: 2px solid #0000;
}

.flexlayout__tabset-selected {
  background-color: var(--color-tabset-background-selected);
}

.flexlayout__tabset-maximized {
  background-color: var(--color-tabset-background-maximized);
}

.flexlayout__tab_button_stamp {
  white-space: nowrap;
  box-sizing: border-box;
  align-items: center;
  gap: .3em;
  display: inline-flex;
}

.flexlayout__tab {
  box-sizing: border-box;
  background-color: var(--color-tab-content);
  color: var(--color-text);
  position: relative;
  overflow: hidden;
}

.flexlayout__tab_moveable {
  box-sizing: border-box;
  min-width: 1px;
  height: 100%;
  min-height: 1px;
  position: relative;
  overflow: auto;
}

.flexlayout__tab_overlay {
  z-index: 20;
  background-color: #0000003d;
  position: absolute;
  inset: 0;
}

.flexlayout__tab_button {
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  gap: .3em;
  padding: 3px .5em;
  display: flex;
}

.flexlayout__tab_button_stretch {
  color: var(--color-tab-selected);
  text-wrap: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0000;
  align-items: center;
  gap: .3em;
  width: 100%;
  padding: 3px 0;
  display: flex;
}

@media (hover: hover) {
  .flexlayout__tab_button_stretch:hover {
    color: var(--color-tab-selected);
  }
}

.flexlayout__tab_button--selected {
  background-color: var(--color-tab-selected-background);
  color: var(--color-tab-selected);
}

@media (hover: hover) {
  .flexlayout__tab_button:hover {
    background-color: var(--color-tab-selected-background);
    color: var(--color-tab-selected);
  }
}

.flexlayout__tab_button--unselected {
  background-color: var(--color-tab-unselected-background);
  color: gray;
}

.flexlayout__tab_button_leading {
  display: flex;
}

.flexlayout__tab_button_content {
  text-wrap: nowrap;
  display: flex;
}

.flexlayout__tab_button_textbox {
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--color-tab-textbox);
  background-color: var(--color-tab-textbox-background);
  border: none;
  border: 1px inset var(--color-1);
  border-radius: 3px;
  width: 10em;
}

.flexlayout__tab_button_textbox:focus {
  outline: none;
}

.flexlayout__tab_button_trailing {
  visibility: hidden;
  border-radius: 4px;
  display: flex;
}

.flexlayout__tab_button_trailing:hover {
  background-color: var(--color-3);
}

@media (hover: hover) {
  .flexlayout__tab_button:hover .flexlayout__tab_button_trailing {
    visibility: visible;
  }
}

.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
  visibility: visible;
}

.flexlayout__tab_button_overflow {
  color: var(--color-overflow);
  font-size: inherit;
  background-color: #0000;
  border: none;
  align-items: center;
  display: flex;
}

.flexlayout__tab_toolbar {
  align-items: center;
  gap: .3em;
  padding-left: .5em;
  padding-right: .3em;
  display: flex;
}

.flexlayout__tab_toolbar_icon {
  font-size: inherit;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 1px;
}

.flexlayout__tab_toolbar_button {
  font-size: inherit;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  outline: none;
  margin: 0;
  padding: 1px;
}

@media (hover: hover) {
  .flexlayout__tab_toolbar_button:hover {
    background-color: var(--color-2);
  }
}

.flexlayout__tab_toolbar_sticky_buttons_container {
  align-items: center;
  gap: .3em;
  padding-left: 5px;
  display: flex;
}

.flexlayout__tab_floating {
  box-sizing: border-box;
  color: var(--color-text);
  background-color: var(--color-background);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: auto;
}

.flexlayout__tab_floating_inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
}

.flexlayout__tab_floating_inner div {
  text-align: center;
  margin-bottom: 5px;
}

.flexlayout__tab_floating_inner div a {
  color: #4169e1;
}

.flexlayout__border {
  box-sizing: border-box;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--color-border);
  background-color: var(--color-border-background);
  display: flex;
  overflow: hidden;
}

.flexlayout__border_tab_contents {
  box-sizing: border-box;
  background-color: var(--color-border-tab-content);
  overflow: hidden;
}

.flexlayout__border_top {
  border-bottom: 1px solid var(--color-border-divider-line);
  align-items: center;
}

.flexlayout__border_bottom {
  border-top: 1px solid var(--color-border-divider-line);
  align-items: center;
}

.flexlayout__border_left {
  border-right: 1px solid var(--color-border-divider-line);
  flex-direction: column;
  align-content: center;
}

.flexlayout__border_right {
  border-left: 1px solid var(--color-border-divider-line);
  flex-direction: column;
  align-content: center;
}

.flexlayout__border_inner {
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flexlayout__border_inner_tab_container {
  white-space: nowrap;
  box-sizing: border-box;
  width: 10000px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  position: absolute;
}

.flexlayout__border_inner_tab_container_right {
  transform-origin: 0 0;
  transform: rotate(90deg);
}

.flexlayout__border_inner_tab_container_left {
  transform-origin: 100% 0;
  flex-direction: row-reverse;
  transform: rotate(-90deg);
}

.flexlayout__border_tab_divider {
  width: 4px;
}

.flexlayout__border_button {
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  align-items: center;
  gap: .3em;
  margin: 2px 0;
  padding: 3px .5em;
  display: flex;
}

.flexlayout__border_button--selected {
  background-color: var(--color-border-tab-selected-background);
  color: var(--color-border-tab-selected);
}

@media (hover: hover) {
  .flexlayout__border_button:hover {
    background-color: var(--color-border-tab-selected-background);
    color: var(--color-border-tab-selected);
  }
}

.flexlayout__border_button--unselected {
  background-color: var(--color-border-tab-unselected-background);
  color: var(--color-border-tab-unselected);
}

.flexlayout__border_button_leading, .flexlayout__border_button_content {
  display: flex;
}

.flexlayout__border_button_trailing {
  visibility: hidden;
  border-radius: 4px;
  display: flex;
}

.flexlayout__border_button_trailing:hover {
  background-color: var(--color-3);
}

@media (hover: hover) {
  .flexlayout__border_button:hover .flexlayout__border_button_trailing {
    visibility: visible;
  }
}

.flexlayout__border_button--selected .flexlayout__border_button_trailing {
  visibility: visible;
}

.flexlayout__border_toolbar {
  align-items: center;
  gap: .3em;
  display: flex;
}

.flexlayout__border_toolbar_left, .flexlayout__border_toolbar_right {
  flex-direction: column;
  padding-top: .5em;
  padding-bottom: .3em;
}

.flexlayout__border_toolbar_top, .flexlayout__border_toolbar_bottom {
  padding-left: .5em;
  padding-right: .3em;
}

.flexlayout__border_toolbar_button {
  font-size: inherit;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 1px;
}

@media (hover: hover) {
  .flexlayout__border_toolbar_button:hover {
    background-color: var(--color-2);
  }
}

.flexlayout__border_toolbar_button_overflow {
  color: var(--color-overflow);
  font-size: inherit;
  background-color: #0000;
  border: none;
  align-items: center;
  display: flex;
}

.flexlayout__popup_menu {
  font-size: var(--font-size);
  font-family: var(--font-family);
}

.flexlayout__popup_menu_item {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px .5em;
}

@media (hover: hover) {
  .flexlayout__popup_menu_item:hover {
    background-color: var(--color-6);
  }
}

.flexlayout__popup_menu_container {
  border: 1px solid var(--color-popup-border);
  color: var(--color-popup-unselected);
  background: var(--color-popup-unselected-background);
  z-index: 1000;
  border-radius: 3px;
  min-width: 100px;
  max-height: 50%;
  padding: 2px;
  position: absolute;
  overflow: auto;
  box-shadow: inset 0 0 5px #00000026;
}

.flexlayout__floating_window _body {
  height: 100%;
}

.flexlayout__floating_window_content {
  position: absolute;
  inset: 0;
}

.flexlayout__error_boundary_container {
  justify-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.flexlayout__error_boundary_content {
  align-items: center;
  display: flex;
}

.flexlayout__tabset_sizer {
  font-size: var(--font-size);
  font-family: var(--font-family);
  padding-top: 5px;
  padding-bottom: 3px;
}

.flexlayout__tabset_header_sizer {
  font-size: var(--font-size);
  font-family: var(--font-family);
  padding-top: 3px;
  padding-bottom: 3px;
}

.flexlayout__border_sizer {
  font-size: var(--font-size);
  font-family: var(--font-family);
  padding-top: 6px;
  padding-bottom: 5px;
  position: absolute;
  top: -30000px;
}

html {
  -webkit-text-size-adjust: 100%;
  min-height: min-content;
  line-height: 1.15;
}

body {
  background-color: #37393e;
  min-height: min-content;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, .border-box {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s;
}

input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.modal-root {
  overflow: scroll;
}
/*# sourceMappingURL=index.c1297003.css.map */
