$color_text: black !default;
$color_background: white !default;
$color_base: white !default;
$color_1: scale_color($color_base, $lightness: -3%) !default;
$color_2: scale_color($color_1, $lightness: -3%) !default;
$color_3: scale_color($color_2, $lightness: -3%) !default;
$color_4: scale_color($color_3, $lightness: -3%) !default;
$color_5: scale_color($color_4, $lightness: -3%) !default;
$color_6: scale_color($color_5, $lightness: -3%) !default;
$color_drag1: rgb(95, 134, 196) !default;
$color_drag2: rgb(119, 166, 119) !default;
$color_drag1_background: rgba(95, 134, 196, 0.1) !default;
$color_drag2_background: rgba(119, 166, 119, 0.075) !default;

$font-size: medium !default;
$font-family: Roboto, Arial, sans-serif !default;

.flexlayout {
    &__layout {
        --color-text: #{$color_text};
        --color-background: #{$color_background};
        --color-base: #{$color_base};
        --color-1: #{$color_1};
        --color-2: #{$color_2};
        --color-3: #{$color_3};
        --color-4: #{$color_4};
        --color-5: #{$color_5};
        --color-6: #{$color_6};
        --color-drag1: #{$color_drag1};
        --color-drag2: #{$color_drag2};
        --color-drag1-background: #{$color_drag1_background};
        --color-drag2-background: #{$color_drag2_background};

        --font-size: #{$font_size};
        --font-family: #{$font_family};

        --color-overflow: gray;
        --color-icon: gray;

        --color-tabset-background: var(--color-background);
        --color-tabset-background-selected: var(--color-1);
        --color-tabset-background-maximized: var(--color-6);
        --color-tabset-divider-line: var(--color-4);

        --color-tabset-header-background: var(--color-background);
        --color-tabset-header: var(--color-text);

        --color-border-tab-content: var(--color-background);
        --color-border-background: var(--color-background);
        --color-border-divider-line: var(--color-4);

        --color-tab-content: var(--color-background);
        --color-tab-selected: var(--color-text);
        --color-tab-selected-background: var(--color-4);
        --color-tab-unselected: gray;
        --color-tab-unselected-background: transparent;
        --color-tab-textbox: var(--color-text);
        --color-tab-textbox-background: var(--color-3);

        --color-border-tab-selected: var(--color-text);
        --color-border-tab-selected-background: var(--color-4);
        --color-border-tab-unselected: gray;
        --color-border-tab-unselected-background: var(--color-2);

        --color-splitter: var(--color-1);
        --color-splitter-hover: var(--color-4);
        --color-splitter-drag: var(--color-4);

        --color-drag-rect-border: #ccc;
        --color-drag-rect-background: var(--color-5);
        --color-drag-rect: var(--color-text);

        --color-popup-border: var(--color-6);
        --color-popup-unselected: var(--color-text);
        --color-popup-unselected-background: #{$color_background};
        --color-popup-selected: var(--color-text);
        --color-popup-selected-background: var(--color-3);

        --color-edge-marker: #aaa;
        --color-edge-icon: #555;
    }
}

@mixin tabset_mixin {
}

@mixin tabset_tabbar_mixin {
}

@mixin tabset_header_mixin {
}

@mixin tabset_selected_mixin {
}

@mixin tabset_maximized_mixin {
}

@mixin tab_top_mixin {
}

@mixin tab_bottom_mixin {
}

@mixin tab_button_mixin {
}

@mixin tab_button_selected_mixin {
}

@mixin tab_button_unselected_mixin {
    color: gray;
}

@mixin tab_button_hovered_mixin {
}

@mixin tab_button_stretch_mixin {
}

@mixin tab_button_stretch_hovered_mixin {
}

@mixin close_button_hovered_mixin {
    background-color:var(--color-3);
}

@mixin toolbar_button_hovered_mixin {
    background-color:var(--color-2);
}

@mixin border_mixin {
}

@mixin border_button_mixin {
}

@mixin border_button_selected_mixin {
}

@mixin border_button_unselected_mixin {
}

@mixin border_button_hovered_mixin {
}

@mixin splitter_mixin {
}

@mixin splitter_hover_mixin {
}

@mixin splitter_drag_mixin {
}

@mixin splitter_border_mixin {
}

@mixin tabset_tab_divider_mixin {
}

@mixin border_tab_divider_mixin {
}

@import "_base";
